/* eslint-disable no-nested-ternary */

import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import GoogleMapReact from 'google-map-react';
import animateScrollTo from 'animated-scroll-to';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormContext } from "react-hook-form";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Chip, Grid, Stepper, Step, StepLabel, MenuItem, Typography, Stack, IconButton, Container, Skeleton, ButtonBase, Tooltip, Card, Button, DialogTitle, DialogContent, Dialog, DialogActions } from '@mui/material';

import ChecklistIcon from '@mui/icons-material/Checklist';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { compressImage } from 'src/hophop/utils';
// import { useBoolean } from 'src/hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';
import { MultiFilePreview } from 'src/components/upload';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import { timeMapping, parkingForLocation, ageIntervalsForListing } from 'src/hophop/utils/_enums';
import { RHFAutocomplete, RHFSelect, RHFTextField, RHFUpload, RHFAutocomplete2 } from 'src/components/hook-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { ColorlibConnector, ColorlibStepIcon } from 'src/hophop/pages/onboarding/components/Colorlib';

import CreateBlogCard from './_CreateBlogCard';


const steps = ['Başlangıç', 'Temel Bilgiler', 'Detay Bilgiler', 'Bitiş'];

const CreateBlog = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    // const addOrUpdateDatePopup = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { accessToken } = useSelector(state => state?.auth)
    const { selectedBlogForModerator } = useSelector(state => state?.blog)


    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (skipped.has(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, skipped]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    useEffect(() => {
        dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
        dispatch(reduxFunc.parameterFunc.fetchCreateActivityParametersForModeratorFunc({ accessToken }))
        dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
    }, [accessToken, dispatch])


    useEffect(() => {
        if (id) {
            dispatch(reduxFunc.blogFunc.fetchSelectedBlogForModeratorFunc({ accessToken, blogId: id }))
        } else (
            dispatch(reduxFunc.blogFunc.resetSelectedBlog())
        )
    }, [accessToken, dispatch, id])



    const NewBlogSchema = Yup.object().shape({

        "title": Yup.string().required("Zorunlu alan!"),
        "category": Yup.array().min(1, 'En az bir tane seçilmeli!').required('Zorunlu alan!'),
        "companionship": Yup.string(),
        "format": Yup.string(),

        "description": Yup.string(),
        "additionalDetails": Yup.string(),
        "coverImage": Yup.string().required("Zorunlu alan!"),
        "images": Yup.array().max(5, "En fazla 5 resim olmalı!"),

        "location": Yup.object({
            "title": Yup.string(),
            "city": Yup.string(),
            "district": Yup.string(),
            "description": Yup.string(),
            "parking": Yup.string(),

            "long": Yup.string(""),
            "lat": Yup.string(""),
        }),

        "schedule": Yup.object({
            "openHour": Yup.string(""),
            "closeHour": Yup.string(""),
        })

    });

    const defaultValues = useMemo(() => ({

        "title": selectedBlogForModerator?.listing?.title ?? "",
        "category": selectedBlogForModerator?.listing?.category ?? [],
        "companionship": selectedBlogForModerator?.listing?.companionship ?? "",
        "format": selectedBlogForModerator?.listing?.format ?? "",
        "description": selectedBlogForModerator?.listing?.description ?? "",
        "coverImage": selectedBlogForModerator?.listing?.coverImage ?? null,
        "images": selectedBlogForModerator?.listing?.images,
        "ageInterval": selectedBlogForModerator?.listing?.ageInterval?.map(item => ageIntervalsForListing.find(sub => sub.value === item)) ?? [],

        "additionalDetails": selectedBlogForModerator?.additionalDetails ?? "",

        "fee": selectedBlogForModerator?.fee ?? "free",

        "phoneNumber": selectedBlogForModerator?.listing?.exteriorOrganizer?.contact?.phoneNumber ?? "",
        "phoneNumber2": selectedBlogForModerator?.listing?.exteriorOrganizer?.contact?.phoneNumber2 ?? "",
        "email": "",

        "website": selectedBlogForModerator?.listing?.exteriorOrganizer?.socialMedia?.website ?? "",
        "youtube": selectedBlogForModerator?.listing?.exteriorOrganizer?.socialMedia?.youtube ?? "",
        "twitter": selectedBlogForModerator?.listing?.exteriorOrganizer?.socialMedia?.twitter ?? "",
        "facebook": selectedBlogForModerator?.listing?.exteriorOrganizer?.socialMedia?.facebook ?? "",
        "instagram": selectedBlogForModerator?.listing?.exteriorOrganizer?.socialMedia?.instagram ?? "",


        "location": {
            "title": selectedBlogForModerator?.location?.address?.title ?? "",
            "city": selectedBlogForModerator?.location?.city ?? "",
            "district": selectedBlogForModerator?.location?.district ?? "",
            "description": selectedBlogForModerator?.location?.address?.description ?? "",
            "parking": selectedBlogForModerator?.location?.parking ?? "",

            "long": selectedBlogForModerator?.location?.long ?? "",
            "lat": selectedBlogForModerator?.location?.lat ?? "",
        },

        "schedule": {
            "openHour": selectedBlogForModerator?.schedule?.openHour ?? "",
            "closeHour": selectedBlogForModerator?.schedule?.closeHour ?? "",
        }

    }), [selectedBlogForModerator]);
    console.log({ selectedBlogForModerator })


    const methods = useForm({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
        mode: "all",
    });

    const { watch, handleSubmit, reset, setValue, getValues } = methods;

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues, id, reset])



    const values = watch();

    const handleCreate = async () => {

        try {

            // ############# cover Image  ###############
            const coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
                listingId: values.title,
                file: values.coverImage,
                accessToken
            })

            const compressedCoverImage = await compressImage(values.coverImage, 0.6)
            await graphqlApi.dms.handleSaveImage({
                file: compressedCoverImage,
                url: coverImageUploadUrl?.presignedUploadUrl
            })

            const coverImageUrl = coverImageUploadUrl.url



            // ############# Images  ###############
            const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
                listingId: values.title,
                file: values?.images,
                accessToken,
                imageCount: values?.images?.length
            })

            await imageUploadUrls.forEach(async (item, i) => {
                const comppressedImages = await compressImage(values.images[i], 0.6)

                await graphqlApi.dms.handleSaveImage({
                    file: comppressedImages,
                    url: item?.presignedUploadUrl
                })
            })

            const imageUrls = imageUploadUrls.map(item => item.url)


            // ############# create listing  ###############
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.blogsForModerator.createBlog({
                    values: {
                        ...values,
                        "ageInterval": values?.ageInterval?.map(item => item?.value)
                    },
                    coverImage: coverImageUrl,
                    images: imageUrls
                }),
                url: "/activity/graphql",
                accessToken,
            });
            console.log({ response })
            if (response?.data?.data?.moderator_createActivityBlog?._id) {
                enqueueSnackbar('Kayıt başarılı!');
                handleNext()
            } else {
                enqueueSnackbar('Kayıt edilemedi!', { variant: "error" });
            }

        } catch (error) {
            console.error(error);
            enqueueSnackbar('Birşeyler ters gitti!', { variant: "error" });
        }

    };

    const handleEdit = async () => {

        try {
            // ############# cover Image  ###############
            let coverImageUploadUrl;
            let coverImageUrl = null
            if (typeof values?.coverImage !== "string") {
                coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
                    listingId: values.title,
                    file: values.coverImage,
                    accessToken
                })
                const compressedCoverImage = await compressImage(values.coverImage, 0.6)

                await graphqlApi.dms.handleSaveImage({
                    file: compressedCoverImage,
                    url: coverImageUploadUrl?.presignedUploadUrl
                })

                coverImageUrl = coverImageUploadUrl.url
            }




            // ############# images  ###############
            const prevImagesUrl = values?.images?.filter(item => typeof item === "string")
            const newImages = values?.images?.filter(item => typeof item !== "string")


            const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
                listingId: values.title,
                file: newImages,
                accessToken,
                imageCount: newImages?.length
            })

            await imageUploadUrls.forEach(async (item, i) => {
                const comppressedImages = await compressImage(newImages[i], 0.6)

                await graphqlApi.dms.handleSaveImage({
                    file: comppressedImages,
                    url: item?.presignedUploadUrl
                })
            })

            const newImagesUrls = imageUploadUrls.map(item => item.url)



            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.blogsForModerator.updateBlog({
                    values: {
                        ...values,
                        "ageInterval": values?.ageInterval?.map(item => item?.value)
                    },
                    coverImage: coverImageUrl ?? values?.coverImage,
                    images: [...prevImagesUrl, ...newImagesUrls],
                    blogId: id
                }),
                url: "/activity/graphql",
                accessToken,
            });
            console.log({ response })
            if (response?.data?.data?.moderator_updateActivityBlog?._id) {
                enqueueSnackbar('Güncelleme başarılı!');
                // setOrganizerOnboardingPageState("last")
                handleNext()
            } else {
                enqueueSnackbar('Güncelleme yapılamadı!', {
                    variant: "error"
                });
            }

        } catch (error) {
            console.error(error);
            enqueueSnackbar('Birşeyler ters gitti!', { variant: "error" });
        }

    };

    const onSubmit = async () => {
        if (id) {
            handleEdit()
        } else {
            handleCreate()
        }
    }


    const renderOrganizerOnboardingPages = useCallback(() => {

        switch (activeStep) {
            case 0:
                return (<FirstPage />)
            case 1:
                return (<FormOne />)
            case 2:
                return (<FormTwo />)
            case 3:
                return (<FormThree />)
            case 4:
                return (<LastPage />)
            default:
                return (<FirstPage />)
        }

    }, [activeStep])


    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <Grid container spacing={3} sx={{ mt: 5 }}>

                <Grid item xs={12}>
                    <CustomBreadcrumbs
                        heading={id ? "Blog Güncelle" : "Blog Oluştur"}
                        sx={{
                            mb: { xs: 3, md: 5 },
                            ml: '50px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={7} >

                    <Stepper
                        activeStep={activeStep - 1}
                        alternativeLabel
                        connector={<ColorlibConnector />}
                        sx={{
                            width: '100%',
                            transition: 'all',
                            transitionDuration: '1s',
                            mb: 5,
                        }}
                    >
                        {steps.map((label, _) => {
                            return (
                                <Step key={label} sx={{ transitionDuration: '1s' }}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                                            {label}
                                        </Box>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack
                            spacing={3}
                            sx={{
                                width: '100%',
                                height: '100%',
                                px: { sm: '10%' }
                            }}
                        >

                            <Box>
                                {renderOrganizerOnboardingPages()}
                            </Box>

                            <Box
                                sx={{
                                    display: (activeStep === 4 || activeStep === 5) ? 'none' : 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >

                                <LoadingButton
                                    variant='contained'
                                    disabled={activeStep === 0}
                                    onClick={() => {
                                        animateScrollTo(0, { maxDuration: 1000 })
                                        handleBack()
                                    }}
                                >
                                    Geri
                                </LoadingButton>

                                <LoadingButton
                                    disabled={
                                        activeStep === 1 && (
                                            !values.title ||
                                            !values.category ||
                                            !values.coverImage
                                        )
                                    }

                                    variant='contained'
                                    onClick={() => {

                                        animateScrollTo(0, { maxDuration: 1000 })

                                        if (activeStep === 3) {
                                            onSubmit()
                                        } else {
                                            handleNext()
                                        }
                                    }}
                                >
                                    {activeStep === 0 ? "Başla" : (activeStep !== 3 ? "İleri" : "Kaydet")}
                                </LoadingButton>
                            </Box>

                        </Stack>
                    </FormProvider>

                </Grid>

                <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: 'block' } }}>
                    <CreateBlogCard post={values} />
                    <br />
                    <MultiFilePreview files={values?.images} thumbnail />
                    {/* {!id && (<MultiFilePreview files={values?.images} />)} */}
                </Grid>

            </Grid>
        </Container>
    )
}


const FirstPage = () => {
    const { id } = useParams()
    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: "center"
            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 2,
                }}
            >
                <ChecklistIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                {id ? "Blog Güncelle" : "Yeni Blog Oluştur"}
            </Typography>
            <Typography
                sx={{
                    fontSize: 20,
                    fontFamily: 'sen !important',
                    color: '#999',
                    textAlign: 'center',
                }}
            >
                Oluşturduğunuz blogun ön izlemesini sağ tarafta görüntüleyebilirsiniz.
            </Typography>
        </Box>
    )
}

const FormOne = () => {

    const { categories } = useSelector(state => state.parameter)
    const { setValue, watch } = useFormContext()
    const values = watch()

    const isFaceToFace = (
        values?.category === "Atölyeler" ||
        values?.category === "Kurslar"
    ) && true

    const categoriesData = categories?.data?.toReversed() ?? [];

    useEffect(() => {
        if (isFaceToFace || values?.category === "") {
            setValue("format", values?.format)
        } else {
            setValue("format", "faceToFace")
        }

    }, [setValue, isFaceToFace, values?.format, values?.category])


    // ################# cover image ###########################
    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('coverImage', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = useCallback(() => {
        setValue('coverImage', null);
    }, [setValue]);



    // ########################### images ############################
    const handleDropImages = useCallback(
        (acceptedFiles) => {
            const files = values.images || [];

            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

            setValue('images', [...files, ...newFiles], { shouldValidate: true });
        },
        [setValue, values.images]
    );

    const handleRemoveImagesFile = useCallback(
        (inputFile) => {
            const filtered = values.images && values.images?.filter((file) => file !== inputFile);
            setValue('images', filtered);
        },
        [setValue, values.images]
    );

    const handleRemoveAllImagesFiles = useCallback(() => {
        setValue('images', []);
    }, [setValue]);


    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={6} >
                <RHFTextField name="title" label="Başlık" />
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFAutocomplete2
                    name="category"
                    label="Kategori"
                    options={categoriesData?.map((option) => option._id)}
                />
            </Grid>

            {/* <Grid item xs={12} md={6} >
                <RHFSelect name="companionship" label="Veli katılımı">
                    {companionshipsForListing.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid> */}

            {/* <Grid item xs={12} md={6} >
                <RHFSelect name="format" label="Format" disabled={!isFaceToFace}>
                    {formatsForListing.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid> */}

            <Grid item xs={12} md={6}>
                <RHFSelect name="schedule.openHour" label="Açılış saati">
                    {timeMapping.map((status) => (
                        <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6}>
                {values?.schedule?.openHour !== 100 && <RHFSelect name="schedule.closeHour" label="Kapanış saati">
                    {
                        timeMapping.slice(timeMapping.map(item => item.value).indexOf(values.schedule.openHour)).map((status) => (
                            <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                {status?.name?.toLocaleLowerCase()}
                            </MenuItem>
                        ))
                    }
                </RHFSelect>}
            </Grid>

            <Grid item xs={12}  >
                <RHFTextField multiline rows={3} name="description" label="Açıklama" />
            </Grid>

            <Grid item xs={12}  >
                <RHFTextField multiline rows={3} name="additionalDetails" label="Ek Detaylar" />
            </Grid>

            <Grid item xs={12}  >
                <Typography variant="subtitle2">Kapak Resmi</Typography>

                <RHFUpload
                    text1="Kapak Resmi"
                    name="coverImage"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                />
            </Grid>

            <Grid item xs={12}  >
                <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Resimler</Typography>
                    <RHFUpload
                        multiple
                        thumbnail
                        name="images"
                        maxSize={3145728}
                        onDrop={handleDropImages}
                        onRemove={handleRemoveImagesFile}
                        onRemoveAll={handleRemoveAllImagesFiles}
                        onUpload={() => console.info('ON UPLOAD')}
                    />
                </Stack>
            </Grid>

        </Grid>
    )
}

const FormTwo = () => {


    const { formState: { errors }, setValue, setError, watch } = useFormContext()
    const values = watch()

    console.log({ values })

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={6} >
                <RHFTextField name="phoneNumber" label="Tel. No." placeholder="--- -------" />
            </Grid>
            <Grid item xs={12} md={6} >
                <RHFTextField name="phoneNumber2" label="Tel. No 2" placeholder="--- -------" />
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFSelect name="fee" label="Ücret seçeneği">
                    <MenuItem value="free">Ücretsiz </MenuItem>
                    <MenuItem value="paid">Ücretli</MenuItem>
                </RHFSelect>
            </Grid>

            <Grid item xs={12} >
                <RHFAutocomplete
                    name="ageInterval"
                    placeholder="Yaş Aralığı"
                    multiple
                    disableCloseOnSelect
                    options={values?.ageInterval?.length === 0 ? ageIntervalsForListing : (values?.ageInterval?.[0]?.name === "Yaş sınırı yok" ? ageIntervalsForListing?.slice(0, 1) : ageIntervalsForListing?.slice(1))}
                    getOptionLabel={(option) => option?.value?.toString()}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.value?.toString()}>
                            {option?.name}
                        </li>
                    )}
                    renderTags={(selected, getTagProps) => {
                        return selected?.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={option?.value?.toString()}
                                label={option?.name}
                                size="small"
                                color="info"
                                variant="soft"
                            />
                        ));
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="website" label="Web Sitesi" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="email" label="Email" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="youtube" label="Youtube" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="twitter" label="Twitter" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="instagram" label="Instagram" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="facebook" label="Facebook" />
            </Grid>-



        </Grid>
    )
}

const FormThree = () => {


    const { cities } = useSelector(state => state.parameter)
    const { formState: { errors }, setValue, setError, watch } = useFormContext()
    const values = watch()

    const [selectedCity, setSelectedCity] = useState([])
    const [isMapVisible, setIsMapVisible] = useState(false);

    useEffect(() => {
        setSelectedCity(cities?.data?.find(item => item?.name === values?.location?.city))
    }, [values?.location?.city, cities?.data])

    useEffect(() => {
        if (values.lat) {
            setError("location.lat", null)
        }
    }, [setError, values.lat])

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={6} >
                <RHFTextField name="location.title" label="Konum Adı" />
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFSelect name="location.parking" label="Park Yeri">
                    {(parkingForLocation)?.map((status, i) => (
                        <MenuItem key={status?.value ?? i} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFSelect name="location.city" label="İl">
                    {(cities?.data ?? [])?.map((status, i) => (
                        <MenuItem key={status?._id ?? i} value={status?.name} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFSelect name="location.district" label="İlçe" disabled={!selectedCity}>
                    {(selectedCity?.districts || [])?.map((status, i) => (
                        <MenuItem key={status?._id ?? i} value={status} sx={{ textTransform: 'capitalize' }}>
                            {status.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                    <MenuItem value="" sx={{ textTransform: 'capitalize' }}>
                        {/* {status.toLocaleLowerCase()} */}
                    </MenuItem>
                </RHFSelect>
            </Grid>

            <Grid item xs={12}  >
                <RHFTextField multiline rows={4} name="location.description" label="Adres" />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', gap:1 }} >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsMapVisible(!isMapVisible)}>
                    {isMapVisible ? "Adresi Seçimini Kapat" : "Haritadan Adres Seç"}
                </Button>
                {isMapVisible && (<GoogleMapComponent setValue={setValue} errors={errors} />)}
            </Grid>



        </Grid>
    )
}

const LastPage = () => {
    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 5,
                }}
            >
                <ThumbUpRoundedIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                Blog başarılı bir şekilde oluşturulmuştur.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 5 }} size='large'
                onClick={() => {
                    window.location.reload()
                }
                }
            >
                Yeni Blog Oluştur
            </Button>
        </Box>
    )
}


const GoogleMapComponent = ({ setValue, errors }) => {

    const defaultProps = {
        center: {
            lat: 41.01258256731506,
            lng: 28.997054894224362,
        },
        zoom: 8
    };

    const [defaultValues, setDefaultValues] = useState(defaultProps.center)

    return (
        <>
            <Box
                sx={{
                    height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    border: errors?.lat?.message ? "3px solid #FF5630" : "",

                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}

                    draggable

                    onClick={(e) => {
                        setDefaultValues({
                            lat: e.lat,
                            lng: e.lng
                        })
                        setValue("location.lat", e.lat)
                        setValue("location.long", e.lng)
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        borderRadius: '8px'
                    }}
                >
                    <AnyReactComponent
                        lat={defaultValues.lat}
                        lng={defaultValues.lng}
                    />
                </GoogleMapReact>
            </Box>

            <Typography
                sx={{
                    color: '#666',
                    fontSize: '12px',
                    mt: '5px'
                }}
            >
                *Konum seçmek veya değiştirmek için haritada tıklama yapmalısınız.
            </Typography>

            <Typography
                sx={{
                    color: '#FF5630',
                    fontSize: '12px',
                    mt: '5px'
                }}
            >
                {errors?.lat?.message}
            </Typography>
        </>
    );
}


const AnyReactComponent = ({ text = "konum", color = "red" }) => {

    return (
        <Box
            sx={{
                position: 'relative',
                top: '-50px',
                left: '-20px',
                width: '40px',
                height: '40px',
                backgroundColor: color,
                transform: 'rotate(45deg)',
                borderRadius: '50%',
                borderEndEndRadius: '0px',
            }}
        >
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: color,
                    transform: 'rotate(-45deg)',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                }}
            >
                {text}
            </Box>
        </Box>
    )
};


export default CreateBlog